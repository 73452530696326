import React from 'react';
import {
  AfterPageProps,
  createApiClient,
  createInitialPropsRedirectToLogin,
  makeAfterComponent,
  preventAuth,
  wrapLayout,
} from '../../@sprinx/react-after-razzle';
import commonThingsToLoad from '../../api/commonThingsToLoad';
import ErrorInternal from '../../components/ErrorInternal';
import CustomerProfilePageLayout from './CustomerProfilePageLayout';

export type CustomerProfilePageProps = AfterPageProps;

const CustomerProfilePage: React.FC<CustomerProfilePageProps> = ({ error }) => {
  if (error) return <ErrorInternal error={error} />;
  return <CustomerProfilePageLayout />;
};

CustomerProfilePage.displayName = 'CustomerProfilePage';

export default makeAfterComponent(
  wrapLayout(CustomerProfilePage),
  preventAuth(async ({ match, initialAppProps: appProps }) => {
    try {
      if (appProps) {
        const apiClient = createApiClient({ accessToken: appProps.auth?.accessToken });

        const commonThings = await Promise.all(commonThingsToLoad.promises({ apiClient }));

        appProps.propagateToState({
          ...commonThingsToLoad.toRegister(commonThings),
        });
      }

      return {};
    } catch (error) {
      if (error.response?.status === 401) {
        return createInitialPropsRedirectToLogin({ match });
      }
      return { error };
    }
  }),
);
