import PasswordValidator from 'password-validator';
import React from 'react';

export default function usePasswordStrongEnough(): (rawPassword: string) => boolean | string[] {
  const schema = React.useMemo(
    () =>
      new PasswordValidator()
        .is()
        .min(8) // Minimum length 8
        .is()
        .max(100) // Maximum length 100
        .has()
        .uppercase() // Must have uppercase letters
        .has()
        .lowercase() // Must have lowercase letters
        .has()
        .digits() // Must have digits
        .has()
        .not()
        .spaces() // Should not have spaces
        .is()
        .not()
        .oneOf(['Passw0rd', 'Password123', '1234', 'password', '123456', 'aaa', 'qwerty']), // Blacklist these values
    [],
  );

  return React.useCallback((rawPassword: string) => schema.validate(rawPassword), [schema]);
}
