import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { apiClientState } from '../../api/appState';

export interface UserSetPasswordCallParams {
  password: string;
  userId: string;
}

export interface UserSetPasswordFormValues {
  confirmPassword: string | undefined;
  password: string | undefined;
}

export interface UserSetPasswordFormErrors {
  confirmPassword?: string;
  password?: string;
}

export interface UserSetPasswordFormTouched {
  confirmPassword?: boolean;
  password?: boolean;
}

export default function useUserSetPasswordHandler(): (params: UserSetPasswordCallParams) => Promise<void> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (params: UserSetPasswordCallParams) =>
      apiClient.post<void, UserSetPasswordCallParams>('/v1/users/set-password', params),
    [apiClient],
  );
}
