import React from 'react';

type SimpleImageProps = {
  alt: string;
  src: string;
  title?: string;
};

export default function SimpleImage(props: SimpleImageProps): JSX.Element {
  return <img src={props.src} alt={props.alt} title={props.title || props.alt} />;
}
