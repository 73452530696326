import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export interface CustomerProfilePageContactCardProps extends StandardDivProps<CustomerProfilePageContactCardClassKey> {
  children: any;
  title?: string;
}

export type CustomerProfilePageContactCardClassKey = 'root' | 'container' | 'title';

const themeSettings = { name: 'CustomerProfilePageContactCard' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CustomerProfilePageContactCardClassKey, {}>({
      root: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 1),
        flexGrow: 1,
      },
      container: {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(2),
        flexGrow: 1,
      },
      title: {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
    }),
  themeSettings,
);

const CustomerProfilePageContactCard = React.forwardRef<HTMLDivElement, CustomerProfilePageContactCardProps>(
  ({ children, className, classes: pClasses, title }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <span className={classes.title}>{title}</span>
        <div className={classes.container}>{children}</div>
      </div>
    );
  },
);

CustomerProfilePageContactCard.displayName = 'CustomerProfilePageContactCard';

export default withThemeProps(themeSettings)(CustomerProfilePageContactCard);
