import SelectField, { SelectFieldProps } from '@sprinx/react-mui-fields/SelectField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { customerSupervisorsQuery } from '../../../api/customer/customerSupervisors';

export type CustomerProfilePageUserSupervisorSelectorProps = SelectFieldProps<string> & {
  customerId: string;
  userId: string;
};

const CustomerProfilePageUserSupervisorSelector: React.FC<CustomerProfilePageUserSupervisorSelectorProps> = ({
  customerId,
  userId,
  value,
  ...rest
}) => {
  const supervisors = useRecoilValue(customerSupervisorsQuery({ customer: customerId, user: userId }));
  return (
    <SelectField {...rest} value={value}>
      <SelectFieldItem value={null}>-- nevybrán --</SelectFieldItem>
      {supervisors.map(({ id, name, email }) => (
        <SelectFieldItem key={id} value={id}>
          {name} ({email})
        </SelectFieldItem>
      ))}
    </SelectField>
  );
};

export default CustomerProfilePageUserSupervisorSelector;
