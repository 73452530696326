import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export interface PageHeadlineProps extends StandardDivProps<PageHeadlineClassKey> {
  actions?: React.ReactNode;
  children: React.ReactNode;
}

export type PageHeadlineClassKey = 'root' | 'title' | 'actions';

const themeSettings = { name: 'SxWebPageHeadline' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    title: {
      ...theme.typography.h4,
      fontWeight: 600,
      color: '#03254F',
      flexGrow: 1,
    },
    actions: {},
  }),
  themeSettings,
);

const PageHeadline: React.FC<PageHeadlineProps> = React.forwardRef(
  ({ actions, className, classes: pClasses, children, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)} {...rest}>
        <h1 className={classes.title}>{children}</h1>
        {actions && <div className={classes.actions}>{actions}</div>}
      </div>
    );
  },
);

PageHeadline.displayName = 'PageHeadline';

export default withThemeProps(themeSettings)(PageHeadline);
