import { atomFamily, selectorFamily } from 'recoil';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export type CustomerSupervisorParams = { customer: string; user: string };

export type CustomerSupervisor = {
  email: string;
  id: string;
  name: string;
};

export async function getcustomerSupervisors(
  apiClient: ApiClient,
  customer: string,
  user: string,
): Promise<CustomerSupervisor[]> {
  return apiClient.get<CustomerSupervisor[], {}>('/v1/users/find-supervisors', { customer, user });
}

export const customerSupervisorsInitialState = GlobalStateRegister.register(
  atomFamily<CustomerSupervisor[] | undefined, CustomerSupervisorParams>({
    key: 'customerSupervisorsInitial',
    default: undefined,
  }),
  'customerSupervisorsInitial',
);

export const customerSupervisorsQuery = selectorFamily<CustomerSupervisor[], CustomerSupervisorParams>({
  key: 'customerSupervisors',
  get:
    ({ customer, user }) =>
    ({ get }) => {
      const initial = get(customerSupervisorsInitialState({ customer, user }));
      if (initial) return initial;

      const apiClient = get(apiClientState);
      return getcustomerSupervisors(apiClient, customer, user);
    },
});
