import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import FormikExtended from '@sprinx/react-forms/FormikExtended';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import clsx from 'clsx';
import qs, { ParsedQs } from 'qs';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useApiClient } from '../../api/appState';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import usePasswordStrongEnough from '../../components/UserSetPasswordDialog/usePasswordStrongEnough';

interface PasswordRenewal {
  password: string | undefined;
  passwordRepeat: string | undefined;
}

interface PasswordRenewalTouched {
  password?: boolean;
  passwordRepeat?: boolean;
}
interface PasswordRenewalErrors {
  password?: string;
  passwordRepeat?: string;
}

interface PasswordRenewalPageProps extends StandardDivProps<PasswordRenewalPageClassKey> {
  location: Location;
}

type State = 'failed' | 'success' | 'form' | 'loading';

export type PasswordRenewalPageClassKey = 'root' | 'content' | 'headline' | 'button' | 'skeletonContainer' | 'form';

const themeSettings = { name: 'PasswordRenewalPage' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<PasswordRenewalPageClassKey, {}>({
      root: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
        width: 500,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      headline: {
        marginTop: 0,
      },
      button: {
        marginTop: theme.spacing(2),
      },
      skeletonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& > span': {
          margin: theme.spacing(0.5, 0),
        },
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  themeSettings,
);

const PasswordRenewalPage: React.FC<PasswordRenewalPageProps> = ({ className, classes: pClasses, location }) => {
  const classes = useStyles({ classes: pClasses });
  const apiClient = useApiClient();
  const [state, setState] = React.useState<State>('loading');
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

  React.useEffect(() => {
    if (apiClient) {
      apiClient
        .post('/v1/users/verify-password-change-token', { token })
        .then((res: any) => {
          setState(res ? 'form' : 'failed');
        })
        .catch(() => setState('failed'));
    }
  }, [apiClient, token]);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <h1 className={classes.headline}>Změna hesla</h1>
        <RenderContent state={state} classes={classes} token={token} onChangeState={setState} />
      </div>
    </div>
  );
};

PasswordRenewalPage.displayName = 'PasswordRenewalPage';

export default withThemeProps(themeSettings)(PasswordRenewalPage);

const RenderContent: React.FC<{
  classes: Record<'skeletonContainer' | 'button' | 'form', string>;
  onChangeState: (value: State) => void;
  state: State;
  token: string | string[] | ParsedQs | ParsedQs[] | undefined;
}> = ({ state, onChangeState: handleChangeState, classes, token }) => {
  if (state === 'loading') {
    return <PasswordRenewalSkeleton className={classes.skeletonContainer} />;
  }
  if (state === 'form') {
    return <Form classes={classes} token={token} onChangeState={handleChangeState} />;
  }
  if (state === 'success') {
    return <SuccessMessage />;
  }
  return <FailedMessage />;
};

const PasswordRenewalSkeleton: React.FC<{ className: string }> = ({ className }) => {
  return (
    <div className={className}>
      <Skeleton width={'100%'} height={20} variant='rect' />
      <Skeleton width={'100%'} height={20} variant='rect' />
      <Skeleton width={'40%'} height={20} variant='rect' />
    </div>
  );
};

PasswordRenewalSkeleton.displayName = 'PasswordRenewalSkeleton';

const Form: React.FC<{
  classes: Record<'button' | 'form', string>;
  onChangeState: (value: State) => void;
  token: string | string[] | ParsedQs | ParsedQs[] | undefined;
}> = ({ classes, onChangeState: handleChangeState, token }) => {
  const passwordStrongEnough = usePasswordStrongEnough();
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const apiClient = useApiClient();

  const handleSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);

      return apiClient
        .post('/v1/users/change-password-by-token', { newPassword: values.password, token })
        .then(() => {
          showMessage({ message: 'Heslo bylo změněno.', variant: 'success' });
          handleChangeState('success');
        })
        .catch(() => {
          showMessage({ message: 'Při odesílání nastala chyba.', variant: 'error' });
          handleChangeState('failed');
        });
    },
    [apiClient, showMessage, handleChangeState, token],
  );

  return (
    <FormikExtended<PasswordRenewal, PasswordRenewalErrors, PasswordRenewalTouched>
      onSubmit={handleSubmit}
      initialValues={{ password: '', passwordRepeat: '' }}
      validationFastest={{
        messages: {
          confirmPassword: 'customerProfilePage/changePassword/confirmPassword',
          passwordStrongEnough: 'customerProfilePage/changePassword/passwordStrongEnough',
        },
      }}
      validationSchema={{
        password: {
          type: 'custom',
          check: function myCheckPassword(value: string): boolean | string[] {
            if (!passwordStrongEnough(value)) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('passwordStrongEnough');
            }
            return true;
          },
        },
        passwordRepeat: {
          type: 'custom',
          check: function myCheck(value: string, schema: any, field: any, parent: PasswordRenewal): boolean | string[] {
            if (value !== parent.password) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('confirmPassword');
            }
            return true;
          },
        },
      }}
      render={({ onChange: handleChange, onSubmit: handleSubmit, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <PasswordField
            label='Heslo'
            value={values.password}
            onChange={handleChange('password')}
            error={errors.password}
          />
          <PasswordField
            label='Heslo pro ověření'
            value={values.passwordRepeat}
            onChange={handleChange('passwordRepeat')}
            error={errors.passwordRepeat}
          />
          <Button type='submit' color='primary' variant='contained' className={classes.button}>
            Změnit
          </Button>
        </form>
      )}
    />
  );
};

const SuccessMessage: React.FC<{}> = () => {
  return (
    <Alert
      severity='success'
      action={
        <Button color='inherit' size='small' href={'/'}>
          Zpět na hlavní stránku
        </Button>
      }
    >
      Heslo bylo úspěšně změněno.
    </Alert>
  );
};

const FailedMessage: React.FC<{}> = () => {
  return (
    <Alert
      severity='warning'
      action={
        <Button color='inherit' size='small' href={'/'}>
          Zpět na hlavní stránku
        </Button>
      }
    >
      Nepodařilo se ověřit token.
    </Alert>
  );
};
