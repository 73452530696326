import { PromiseType } from 'utility-types';
import { ApiClient } from '../@sprinx/react-after-razzle';
import { getProductTaxonomyTypes, productTaxonomyTypesInitialState } from './products/taxonomyTypes';

export interface CommonThingsToLoadProps {
  apiClient: ApiClient;
}

const commonThingsToLoad = transform({
  [productTaxonomyTypesInitialState.key]: ({ apiClient }: CommonThingsToLoadProps) =>
    getProductTaxonomyTypes(apiClient),
});

export default commonThingsToLoad;

function transform(cfg: CommonThingsToLoadCfg): CommonThingsToLoad {
  return {
    keys: Object.keys(cfg),
    promises: (props: CommonThingsToLoadProps): Promise<any>[] => Object.values(cfg).map((fn) => fn(props)),
    toRegister: (things: any[]): Record<string, any> =>
      Object.keys(cfg).reduce((acc, key, idx) => ({ ...acc, [key]: things[idx] }), {}),

    // getters
    taxonomyTypes: (things) => things[0],
  };
}

type CommonThingsToLoadCfg = Record<string, (props: CommonThingsToLoadProps) => Promise<any>>;

interface CommonThingsToLoad {
  keys: string[];
  promises: (props: CommonThingsToLoadProps) => Promise<any>[];
  taxonomyTypes: (things: any[]) => PromiseType<ReturnType<typeof getProductTaxonomyTypes>>;
  toRegister: (things: any[]) => Record<string, any>;
}
